import React from 'react'
import Img from 'gatsby-image/index'

import Layout from './layout'
import SEO from './seo'
import fbSvg from '../assets/facebook.svg'
import instaSvg from '../assets/instagram.svg'
import LeafletMap from './leafletMap'
import config from '../config'

export const Restaurant = ({ page, fluid, gallery, menu, delivery, menuInPdf }) => {
  const {
    title,
    description,
    contacts: { date, time, bot, fb, ig, tel, address, map },
  } = config[page]
  return (
    <Layout showHeader page={page} className={page}>
      <SEO title={title} />
      {/*About*/}
      <section className="introSection container">
        <h2>{title}</h2>
      </section>
      <Img fluid={fluid} className="titleImage" />

      {/*Gallery*/}
      <section className="gallery-grid container">
        <div className="gallery-row">
          {gallery[0] && <img alt="Фото у Супстанції 1" src={gallery[0]} className="gallery-img-1" />}
          {gallery[1] && <img alt="Фото у Супстанції 2" src={gallery[1]} className="gallery-img-2" />}
        </div>
        <div className="gallery-row">
          {gallery[2] && <img alt="Фото у Супстанції 3" src={gallery[2]} className="gallery-img-3" />}
          {gallery[3] && <img alt="Фото у Супстанції 4" src={gallery[3]} className="gallery-img-4" />}
        </div>
      </section>

      {/*Menu*/}
      {menu && (
        <section className="menuSection container">
          <div className="title">
            <a rel="noreferrer noopener" target="_blank" href="https://expz.menu/5c7bccbb-805c-45d0-bb33-9f4e3cab5b26">
              Наше меню
            </a>
          </div>
        </section>
      )}

      <section className="videoBlock container">
        {/*<h2>Наша история</h2>*/}
        {/*<hr className="headerLine" />*/}
        <div>
          <iframe
            src="https://www.youtube.com/embed/dpA4vhd3Kvk"
            allow="accelerometer; fullscreen; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </section>

      {/*Contacts*/}
      <section className="contactSection container">
        <h2>Наші контакти</h2>
        <div className="contacts">
          {date && <div className="date">{date}</div>}
          {time && <div className="time">{time}</div>}
          {bot && (
            <div style={{ marginBottom: 5 }}>
              Telegram-бот:{' '}
              <a href={`https://t.me/${bot}`} target="_blank" rel="noopener noreferrer">
                {bot}
              </a>
            </div>
          )}
          {delivery && <div className="delivery">{delivery}</div>}
          <div>
            Зробити замовлення:{' '}
            <a className="phone" href={`tel:${tel}`}>
              {tel}
            </a>
          </div>
          <div>{address}</div>
          <div className="links">
            <a href={`https://www.facebook.com/${fb}/`} target="_blank" rel="noopener noreferrer">
              <img alt="fbIcon" src={fbSvg} className="contactIcon fb" />
            </a>
            <a href={`https://www.instagram.com/${ig}/`} target="_blank" rel="noopener noreferrer">
              <img alt="instagramIcon" src={instaSvg} className="contactIcon" />
            </a>
          </div>
        </div>
      </section>
      {/*map*/}
      {typeof window !== 'undefined' && (
        <LeafletMap
          position={map} // Your Coordinates
          zoom={18} // Zoom Level
          markerText={address} // Icon text
        />
      )}
    </Layout>
  )
}
